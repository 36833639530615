import React from "react";
import {
  Box,
  Button,
  Chip,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import Page from "./Page";
import { QrReader } from "react-qr-reader";
import { useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../sql";
import beep1 from "../Assets/beep-08b.mp3";
import useSound from "use-sound";

export default function ScanningVisitorsPage() {
  const { data, error, loading } = useQuery(GET_MY_AUTH);

  return (
    <Page title="Badge Scanner">
      <Typography variant="h4" gutterBottom align="center">
        Badge QR Scanner
      </Typography>
      {loading ? (
        <LinearProgress />
      ) : !!error ? (
        <Typography color="error">{JSON.stringify(error, null, 2)}</Typography>
      ) : data?.eventId && data?.standId ? (
        <ScanAndUpload eventId={data.eventId} standId={data.standId} />
      ) : (
        ""
      )}
    </Page>
  );
}

function ScanAndUploadOld({ eventId, standId }) {
  const [uploadScanResult, { loading }] = useMutation(UPLOAD_SCAN_RESULT);

  const [playBeepSuccess] = useSound(beep1);
  const [playBeepFailed] = useSound(beep1, { playbackRate: 0.4 });

  const [state, setState] = React.useState({
    status: false,
    message: "",
    waiting: false,
  });

  const uploadFunction = async (data) => {
    try {
      const resp = await uploadScanResult({
        variables: {
          role: data.role,
          id: data.id,
        },
      });

      if (!resp.data.result) {
        throw new Error("Something went wrong");
      }

      setState({ waiting: true, status: true, message: resp.data.result });

      if (resp.data.result.includes("Already")) {
        playBeepFailed();
      } else {
        playBeepSuccess();
      }
    } catch (e) {
      setState({ waiting: true, status: false, message: e.message });
      playBeepFailed();
    }
  };

  const handleScanComplete = async (data) => {
    if (data.eventId !== eventId) {
      throw new Error("Invalid Event ID ");
    }
    // if (data.role !== "visitor") {
    //   throw new Error("Not a visitor");
    // }
    if (state.waiting) {
      console.log("yup waiting");
      return;
    }

    setState({ ...state, status: false, message: "", waiting: true });
    await uploadFunction(data);
    setTimeout(
      () => setState({ status: false, message: "", waiting: false }),
      1500
    );
  };

  const handleScanError = (e) => {
    console.log(e);
    setState({ status: false, message: e.message });
  };

  const [data, setData] = React.useState({});

  React.useEffect(() => {
    handleScanComplete(data);
  }, [data.eventId, data.id, data.role, data.timeout]);

  return (
    <>
      <Scanner
        onScanComplete={(d) =>
          setData({ ...d, timeout: Math.floor(new Date().getSeconds() / 5) })
        }
        onError={handleScanError}
      />
      {loading ? (
        <Box display="flex" justifyContent="center" p={1}>
          <LinearProgress />
        </Box>
      ) : (
        <Box display="flex" justifyContent="center" p={1}>
          {state.status ? (
            // <Typography color="success">{state.message}</Typography>
            <Chip
              color="success"
              label={state.message}
              size="large"
              sx={{ fontSize: 20, p: 2 }}
            />
          ) : (
            <Typography color="error">{state.message}</Typography>
          )}
        </Box>
      )}
    </>
  );
}

function ScanAndUpload({ eventId }) {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const [error, setError] = React.useState(null);
  const [message, setMessage] = React.useState(null);
  const [playBeepSuccess] = useSound(beep1);
  const [playBeepFailed] = useSound(beep1, { playbackRate: 0.4 });

  const handleScan = (d) => {
    if (loading) return;
    setData({ ...d, timeout: Math.floor(new Date().getSeconds() / 5) });
  };
  const handleError = (e) => {
    setError(e.message);
    setMessage(null);
  };

  const [uploadData] = useMutation(UPLOAD_SCAN_RESULT, {
    onError: handleError,
  });

  React.useEffect(() => {
    if (!data?.eventId || !data?.role || !data?.id) {
      playBeepSuccess();
      playBeepFailed();
      return;
    }
    if (data.eventId !== eventId) {
      playBeepFailed();
      return setError("Invalid Event QR");
    }
    // if (data.role !== "visitor") {
    //   playBeepFailed();
    //   return setError("Not a visitor QR");
    // }

    console.log("new data scanned", data);

    let t;
    (async () => {
      setLoading(true);
      setError(null);
      setMessage(null);
      const m = await uploadData({
        variables: {
          role: data.role,
          id: data.id,
        },
      });
      playBeepSuccess();
      setMessage(m.data.result || "");
      setLoading(false);
      await new Promise((resolve) => {
        t = setTimeout(resolve, 2000);
      });
      setMessage(null);
    })();

    return () => {
      clearTimeout(t);
    };
  }, [data.eventId, data.id, data.role, data.timeout]);

  return (
    <>
      <Scanner onScan={handleScan} onError={handleError} />
      <Box display="flex" justifyContent="center" p={1}>
        {loading ? (
          <LinearProgress />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : message ? (
          <Chip
            color="success"
            label={message}
            size="large"
            sx={{ fontSize: 20, p: 2 }}
          />
        ) : (
          ""
        )}
      </Box>
    </>
  );
}

function Scanner({ onScan, onError }) {
  return (
    <>
      <QrReader
        constraints={{
          facingMode: "environment",
        }}
        onResult={(r, e) => {
          try {
            const t = r?.getText();
            if (!t) {
              throw e || new Error("Invalid QR");
            }
            const [eventId, role, id] = t.split(",");
            if (!eventId || !role || !id) {
              throw new Error("Invalid QR");
            }
            onScan({ eventId, role, id });
          } catch (e) {
            onError(e);
          }
        }}
      />
      <Box display="flex" justifyContent="center">
        {/* <Button
          onClick={() =>
            onScan({
              eventId: "5653333908",
              role: "visitor",
              id: "123",
            })
          }
        >
          Temp Scan
        </Button> */}
      </Box>
    </>
  );
}

const GET_MY_AUTH = gql`
  query {
    eventId
    standId
  }
`;

const UPLOAD_SCAN_RESULT = gql`
  mutation ($role: String!, $id: String!) {
    result: uploadScanResult(role: $role, id: $id)
  }
`;
