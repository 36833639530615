import React from "react";

export default function TestPage() {
  const [data, setData] = React.useState({});

  const handleScan = (d) => {
    setData({ ...d, timeout: Math.floor(new Date().getSeconds() / 5) });
  };

  React.useEffect(() => {
    console.log({ data });
  }, [data.eventId, data.id, data.role, data.timeout]);

  return (
    <>
      I am parent
      <Scanner onScan={handleScan} />
    </>
  );
}

function Scanner({ onScan }) {
  return (
    <>
      Click to temp scan
      <br />
      <button
        onClick={() =>
          onScan({
            eventId: "1",
            role: "visitor",
            id: "11",
          })
        }
      >
        Scan 1
      </button>
      <button
        onClick={() =>
          onScan({
            eventId: "1",
            role: "visitor",
            id: "22",
          })
        }
      >
        Scan 2
      </button>
    </>
  );
}
