export const API_URL = "https://api-exhibitor.eventegration.com/graphql/";
// export const API_URL = "http://localhost:4002/graphql";

export const downloadFile = (document, content, filename) => {
  const element = document.createElement("a");
  const file = new Blob([content], {
    type: "text/plain",
  });
  element.href = URL.createObjectURL(file);
  element.download = filename;
  document.body.appendChild(element);
  element.click();
};

export function csvToArray(str, delimiter = ",") {
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = str
    .slice(0, str.indexOf("\n"))
    .split(delimiter)
    .map((h) => h.trim());

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = str.slice(str.indexOf("\n") + 1).split("\n");
  const arr = rows.map(function (row) {
    const values = row.split(delimiter);
    const el = headers.reduce(function (object, header, index) {
      object[header] = values[index]?.trim() || "";
      return object;
    }, {});
    return el;
  });

  // return the array
  return arr;
}
