import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Backspace, Launch, QrCodeScanner, Refresh } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Page from "./Page";
import { Icon } from "@iconify/react";
import { format } from "date-fns";

export default function MyVisitorsPage() {
  const navigate = useNavigate();
  const [search, setSearch] = React.useState("");

  return (
    <Page title="My Scanned Data">
      <Typography variant="h4" gutterBottom>
        My Scanned Data
      </Typography>

      <Stack spacing={2}>
        <SearchBox onSearch={setSearch} />

        <ApplicantsTable search={search} role="all" />

        <Button
          size="large"
          variant="outlined"
          startIcon={<Launch />}
          endIcon={<Icon icon="fluent:qr-code-28-regular" />}
          onClick={() => navigate("scanner")}
        >
          Open Badge Scanner
        </Button>
      </Stack>

      <Box p={6} />
    </Page>
  );
}

function SearchBox({ onSearch }) {
  const [search, setSearch] = React.useState("");
  React.useEffect(() => {
    const t = setTimeout(() => onSearch(search), 500);
    return () => clearTimeout(t);
  }, [search]);
  return (
    <TextField
      sx={{ maxWidth: 500 }}
      label="Search here..."
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      InputProps={{
        autoComplete: "new-password",
        endAdornment: (
          <InputAdornment position="end">
            {search && (
              <IconButton onClick={() => setSearch("")}>
                <Backspace />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
}

function CustomFooter({ refetch }) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Box
      sx={{
        width: "100%",
        p: 1,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <IconButton onClick={() => refetch()} title="Refresh">
        <Refresh />
      </IconButton>
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    </Box>
  );
}

function ApplicantsTable({ search, role }) {
  const limit = 100;
  const { data, error, refetch, loading } = useQuery(GET_DATA, {
    variables: {
      conditions: { limit, text: search },
      role,
    },
  });
  // const [columns, setColumns] = React.useState([]);

  // React.useEffect(() => {
  //   if (!data?.applicants?.length) {
  //     return;
  //   }
  //   const keys = Object.keys(JSON.parse(data.applicants[0].data));

  //   setColumns(keys.map((k) => ({ field: k, width: 280 })));
  // }, [data]);

  return (
    <Box>
      <DataGrid
        loading={loading}
        sx={{ height: 550 }}
        sortModel={[{ field: "created", sort: "desc" }]}
        components={{
          Toolbar: GridToolbar,
          Footer: CustomFooter,
        }}
        componentsProps={{
          footer: { refetch },
        }}
        columnVisibilityModel={{
          id: false,
          created: false,
        }}
        // columns={[{ field: "id" }, ...columns]}
        columns={[
          { field: "id", headerName: "ID", width: 110 },
          {
            field: "created",
            headerName: "Scanned At",
            width: 200,
            // valueFormatter: (p) => format(+p.value, "dd MMM, yyyy hh:mm a"),
          },
          {
            field: "role",
            headerName: "Data",
            valueGetter: (p) => p.value?.toUpperCase(),
          },
          { field: "First Name", width: 150 },
          { field: "Last Name", width: 150 },
          { field: "Company Name", width: 300 },
          { field: "Designation", width: 200 },
          { field: "Email", width: 250 },
          { field: "Country", width: 70 },
          {
            field: "Mobile",
            width: 200,
            valueGetter: (p) => `${p.row.calling_code} ${p.value}`,
          },
        ]}
        rows={
          data?.applicants?.map((v) => ({
            id: v.id,
            created: v.created,
            role: v.role,
            ...JSON.parse(v.data),
          })) || []
        }
        paginationMode="server"
        pageSize={limit}
        rowsPerPageOptions={[limit]}
        onPageChange={(p) =>
          refetch({
            conditions: {
              offset: p * limit,
              limit,
            },
            role,
          })
        }
        error={error}
        rowCount={data?.total || 0}
      />
    </Box>
  );
}

const GET_DATA = gql`
  query ($conditions: InputConditions, $role: String!) {
    applicants(conditions: $conditions, role: $role) {
      id
      data
      created
      role
    }
    total: applicantsCount(conditions: $conditions, role: $role)
  }
`;
